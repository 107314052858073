import { Row } from "./components/Row";
import { AccessRegistryCardView } from "./skeletons/AccessRegistryCardView";
import { CommunityMemberAccessControlView } from "./skeletons/AdminMemberEditModal/Access/CommunityMemberAccessControlView";
import { AccessGroupCardControlView } from "./skeletons/AdminMemberEditModal/Access/CommunityMemberAccessControlView/AccessGroupCardControlView";
import { Bookmarks } from "./skeletons/Bookmarks";
import { CardView } from "./skeletons/CardView";
import { ChatThreadsListHeader } from "./skeletons/ChatThreadsListHeader";
import { CommunitySidebar } from "./skeletons/CommunitySidebar";
import { CommunitySidebarSkeleton } from "./skeletons/CommunitySidebarV2";
import { CommunitySwitcherSkeleton } from "./skeletons/CommunitySwitcher";
import { EmailPreview } from "./skeletons/EmailPreview";
import { EventsListView } from "./skeletons/EventsListView";
import { EventItem } from "./skeletons/EventsListView/EventItem";
import { EventPopover } from "./skeletons/EventsListView/EventPopover";
import { NewEventDetail } from "./skeletons/EventsListView/NewEventDetail";
import { NewEventsListView } from "./skeletons/EventsListView/NewEventsListView";
import { NewEventsThumbnailView } from "./skeletons/EventsListView/NewEventsThumbnailView";
import { FeedView } from "./skeletons/FeedView";
import { FullPostView } from "./skeletons/FullPostView";
import { GridView } from "./skeletons/GridView";
import { ListView } from "./skeletons/ListView";
import { MainHeader } from "./skeletons/MainHeader";
import { MemberCard } from "./skeletons/MemberCard";
import { MembersAvatarRow } from "./skeletons/MembersAvatarRow";
import { MembersAvatarRowV3 } from "./skeletons/MembersAvatarRowV3";
import { ChatRoom } from "./skeletons/Messaging/ChatRoom";
import { ChatRoomSidebar } from "./skeletons/Messaging/ChatRoomSidebar";
import { ChatThread } from "./skeletons/Messaging/ChatThread";
import { ChatThreadsListPage } from "./skeletons/Messaging/ChatThreadsListPage";
import { MessagingListPage } from "./skeletons/Messaging/MessagingListPage";
import { RailBar } from "./skeletons/Rail";
import { RailMemberList } from "./skeletons/Rail/RailMemberList";
import { RailProfile } from "./skeletons/Rail/RailProfile";
import { RightSidebar } from "./skeletons/RightSidebar";
import { SpaceHeader } from "./skeletons/SpaceHeader";
import { SpaceMemberListPage } from "./skeletons/SpaceMemberListPage";
import { Transcription } from "./skeletons/Transcription";
import "./styles.scss";

/* TODO: To implement when migrating to Typescript */
export const variantToComponentMap: any = {
  "app-bar": MainHeader,
  "right-sidebar": RightSidebar,
  "feed-view": FeedView,
  "card-view": CardView,
  "grid-view": GridView,
  "list-view": ListView,
  "community-switcher": CommunitySwitcherSkeleton,
  "community-sidebar": CommunitySidebar,
  "community-sidebar-v2": CommunitySidebarSkeleton,
  "space-header": SpaceHeader,
  "member-card": MemberCard,
  "space-member-list-page": SpaceMemberListPage,
  "rail-bar": RailBar,
  "rail-member-list": RailMemberList,
  "rail-profile": RailProfile,
  "messaging-list-page": MessagingListPage,
  "chat-room": ChatRoom,
  "chat-thread": ChatThread,
  "chat-threads-list-page": ChatThreadsListPage,
  bookmarks: Bookmarks,
  transcription: Transcription,
  "full-post-view": FullPostView,
  "members-avatar-row": MembersAvatarRow,
  "members-avatar-row-v3": MembersAvatarRowV3,
  "events-list-view": EventsListView,
  "new-events-list-view": NewEventsListView,
  "new-events-thumbnail-view": NewEventsThumbnailView,
  "new-event-detail": NewEventDetail,
  "chat-room-sidebar": ChatRoomSidebar,
  "chat-threads-list-header": ChatThreadsListHeader,
  "email-preview": EmailPreview,
  "event-popover": EventPopover,
  "event-item": EventItem,
  row: Row,
  "access-registry-card": AccessRegistryCardView,
  "community-member-access-control": CommunityMemberAccessControlView,
  "community-member-access-group-card-control": AccessGroupCardControlView,
};

export type SkeletonVariant = keyof typeof variantToComponentMap;

export interface SkeletonLoaderProps {
  variant: SkeletonVariant;
  show?: boolean;
  [key: string]: any /* TODO: To implement when migrating to Typescript */;
}

export const SkeletonLoader = ({
  variant,
  show = true,
  ...rest
}: SkeletonLoaderProps) => {
  const Component = variantToComponentMap[variant];
  if (!show) {
    return null;
  }
  return <Component {...rest} />;
};

// eslint-disable-next-line no-barrel-files/no-barrel-files -- TODO: Migrate to named exports
export * from "./components/";
// eslint-disable-next-line no-barrel-files/no-barrel-files -- TODO: Migrate to named exports
export * from "./skeletons/";
