import { t } from "@/i18n-js/instance";
import {
  checkMessageApiAccess,
  hasMessageAccess,
} from "@circle-react/components/shared/uikit/TipTap/Extensions/CustomExtensions/ChatMessageLink/helper";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useEnterOrSpaceKeyDownHandler } from "@circle-react/hooks/useEnterOrSpaceKeyDownHandler";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { Typography } from "@circle-react-uikit/Typography";

interface MessageItemProps {
  message: any;
}

export const MessageItem = ({ message }: MessageItemProps) => {
  const currentCommunityMember = useCurrentCommunityMember();
  const [shouldShowAlert, toggleShouldShowAlert] = useBoolean();
  const { chatRoomAccessArr = [], editor } = useTipTapEditorContext() || {};
  const {
    sender,
    data: {
      url,
      chat_room_uuid: chatRoomUuid,
      message_body,
      sent_at: sentAt,
      attachments_count: attachmentsCount = 0,
    },
    sender: { community_member_id: senderCommunityMemberId },
    id,
  } = message;
  const timeAgo = dateStringToTimeAgo(sentAt);

  const redirectToLink = async () => {
    const hasAccessArray = chatRoomAccessArr.length > 0;
    const shouldCheckApiAccess = !editor.isEditable && !hasAccessArray;
    const isMessageOfCurrentCommunityMember =
      senderCommunityMemberId === currentCommunityMember.id;

    const checkMessageAccess = () =>
      editor.isEditable
        ? true // Message yet to be created
        : hasMessageAccess(id, chatRoomAccessArr);

    const checkApiAccess = () =>
      checkMessageApiAccess({
        chatRoomUuid,
        messageId: id,
      });

    const hasAccess = isMessageOfCurrentCommunityMember
      ? true
      : shouldCheckApiAccess
        ? await checkApiAccess()
        : checkMessageAccess();

    if (hasAccess) {
      window.open(url, "_blank", "noreferrer");
    } else {
      toggleShouldShowAlert();
    }
  };

  const onKeyDownHandler = useEnterOrSpaceKeyDownHandler(
    () => void redirectToLink(),
  );

  return (
    <>
      <button
        type="button"
        onClick={redirectToLink}
        onKeyDown={onKeyDownHandler}
        className="border-primary focus-visible:border-secondary flex w-full min-w-[150px] max-w-[300px] shrink-0 cursor-pointer flex-row space-x-3 rounded-md border px-4 py-3 text-left"
      >
        <UserImage src={sender.avatar_url} name={sender.name} size="10" />
        <div
          // 40px for avatar and 12px for padding
          className="max-w-[calc(100%-52px)]"
        >
          <div className="mb-0.5 flex flex-row space-x-2 truncate">
            <Typography.LabelMd weight="semibold">
              {sender.name}
            </Typography.LabelMd>
            <Typography.BodyXs color="text-light">{timeAgo}</Typography.BodyXs>
          </div>

          {message_body && (
            <div className="text-default mb-1 line-clamp-2 flex-1">
              {message_body}
            </div>
          )}
          {attachmentsCount > 0 && (
            <Typography.BodySm color="text-light">
              {t("bookmarks.attachments_count", { count: attachmentsCount })}
            </Typography.BodySm>
          )}
        </div>
      </button>
      <ConfirmationModal
        isOpen={shouldShowAlert}
        title={t("messaging.actions.unable_to_open_link.title")}
        onConfirm={toggleShouldShowAlert}
        confirmVariant="danger"
        confirmText={t("close")}
      >
        <Typography.BodyMd>
          {t("messaging.actions.unable_to_open_link.description")}
        </Typography.BodyMd>
      </ConfirmationModal>
    </>
  );
};
