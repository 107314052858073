import React, { useEffect } from "react";
import { Node } from "@tiptap/core";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from "@tiptap/react";
import { truncate } from "lodash";
import { closeHistory } from "prosemirror-history";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { contentTypeParser } from "@circle-react-shared/uikit/TipTap/utilities/contentTypeParser";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useFileUpload } from "./useFileUpload";

const i18nRoot = "tiptap.file_upload";

export const FileLoaderExtension = Node.create({
  name: "file-loader",
  group: "inline",
  inline: true,
  atom: true,
  addAttributes: () => ({
    file: {
      default: null,
    },
  }),
  parseHTML: () => [{ tag: "file-loader" }],
  renderHTML: ({ HTMLAttributes }) => [
    "file-loader",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView: () => ReactNodeViewRenderer(Renderer),
});

const Renderer = ({ node, editor, HTMLAttributes, getPos }) => {
  const isEditable = editor.isEditable;
  const file = node.attrs.file;
  const { addToLocalInlineAttachments } = useBlockEditorContext();

  const deleteNodeWithoutHistory = position => {
    if (!position) return;
    editor.view.dispatch(
      editor.state.tr
        .delete(position, position + node.nodeSize)
        .setMeta("addToHistory", false),
    );
  };

  const { status, progress, error, addFile, cancelUpload } = useFileUpload({
    onComplete: file => {
      const { signed_id, content_type } = file;
      const isImage = contentTypeParser.isImage(content_type);
      const position = getPos();

      addToLocalInlineAttachments(file);

      // We pass position instead of fetching it within the function so that we can keep
      // track of the position of the node before it is deleted.
      deleteNodeWithoutHistory(position);

      editor.view.dispatch(
        closeHistory(
          editor.state.tr.insert(
            position,
            editor.schema.nodes[isImage ? "image" : "file"].create({
              signed_id,
            }),
          ),
        ),
      );
    },
  });

  const isValidFile = file instanceof File;

  useEffect(() => {
    if (!isEditable) return;
    if (!isValidFile) {
      const position = getPos();
      deleteNodeWithoutHistory(position);
      return;
    }
    addFile(file);

    return () => cancelUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- description
  }, []);

  if (!isEditable || !isValidFile) {
    return null;
  }

  const getStatusMessage = () => {
    switch (status) {
      case "preparing":
        return t([i18nRoot, "preparing"]);
      case "uploading":
        return t([i18nRoot, "uploading"], { progress: progress.toFixed(0) });
      case "processing":
        return t([i18nRoot, "processing"]);
      case "failed":
        return t([i18nRoot, "errors", error]);
      default:
        return "";
    }
  };

  return (
    <NodeViewWrapper as="div" HTMLAttributes={HTMLAttributes}>
      <div className="bg-secondary border-primary relative my-2 inline-flex w-full space-x-3 rounded border px-3 py-2 md:w-[75%] lg:w-[50%]">
        {status === "failed" ? (
          <Icon type="20-alert" className="!h-5 !w-5" size={20} />
        ) : (
          <Icon type="loader" className="animate-spin" size={24} />
        )}
        <div className="flex flex-col">
          <Typography.LabelSm color="text-dark" weight="semibold">
            {truncate(file.name, 15)}
          </Typography.LabelSm>
          <Typography.LabelSm>{getStatusMessage()}</Typography.LabelSm>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
