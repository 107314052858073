import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { ProgressiveImage } from "@circle-react-shared/ProgressiveImage";
import { AlignmentPicker } from "./AlignmentPicker";

interface ImageContainerProps {
  width: string;
  node: any;
  isEditable: boolean;
  attachmentMeta: any;
  onClickOpenAttachmentGallery: () => void;
  updateAttributes: (attrs: any) => void;
}

export const ImageContainer = ({
  width,
  node,
  isEditable,
  attachmentMeta,
  onClickOpenAttachmentGallery,
  updateAttributes,
}: ImageContainerProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isProgressiveImageLoadingOnPostsEnabled =
    currentCommunitySettings?.progressive_image_loading_on_post_space_enabled;

  return (
    <div
      style={{ width }}
      className={classNames("group relative", {
        "mx-auto": node.attrs.alignment === "center",
      })}
      aria-label={t("tiptap.extensions.image_preview.view_attachment")}
    >
      <button
        type="button"
        className="w-full"
        onClick={onClickOpenAttachmentGallery}
        aria-label={t("tiptap.extensions.image_preview.view_attachment")}
      >
        {isProgressiveImageLoadingOnPostsEnabled ? (
          <ProgressiveImage
            alt=""
            src={attachmentMeta.url}
            className={classNames("w-full", {
              "cursor-zoom-in": !isEditable,
            })}
            srcVariants={attachmentMeta?.image_variants}
          />
        ) : (
          <img
            loading="lazy"
            alt=""
            className={classNames("w-full", {
              "cursor-zoom-in": !isEditable,
            })}
            src={attachmentMeta.url}
          />
        )}
      </button>
      {isEditable && (
        <AlignmentPicker node={node} updateAttributes={updateAttributes} />
      )}
    </div>
  );
};
