import type { ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import classNames from "classnames";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

interface ListProps {
  children: ReactNode;
  className?: string;
  underline?: boolean;
  listClassName?: string;
}

export const List = ({
  children,
  className = "",
  listClassName = "",
  ...props
}: ListProps) => (
  <OverlayScrollbarsComponent
    className={className}
    options={{
      scrollbars: {
        autoHide: "leave",
        autoHideDelay: 0,
      },
    }}
  >
    <HeadlessUITab.List
      as="nav"
      data-testid="tab-list"
      className={classNames("flex gap-6", listClassName)}
      {...props}
    >
      {children}
    </HeadlessUITab.List>
  </OverlayScrollbarsComponent>
);

List.displayName = "Tab.List";
