import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface ListWrapperProps {
  hasBorder?: boolean;
  children: ReactNode;
}

export const ListWrapper = ({
  hasBorder = true,
  children,
}: ListWrapperProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("bg-primary px-6", {
        "border-primary border-b": hasBorder,
        "lg:px-9": isV3Enabled,
      })}
    >
      {children}
    </div>
  );
};
